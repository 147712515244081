.link {
  display: inline;
  color: var(--marketplaceColor);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.buttonLink{
  color: var(--colorWhite);
  margin-top: auto; 
  display: inline-block;
}